'use client';

import clsx from 'clsx';
import { ReactNode } from 'react';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    children: ReactNode;
    variant?: 'primary' | 'secondary';
}

export const Button: React.FC<ButtonProps> = ({ children, className, variant = 'solid', ...props }) => (
    <button className={clsx(`${variant}Button`, className)} type="button" {...props}>
        {children}
    </button>
);
