'use client';

import { useEffect, useState } from 'react';

import { useCookieStore } from './store/cookieStore';

import { Button } from '@/components/core/Button/Button';
import { Typography } from '@/components/typography/Typography';
import { useTranslations } from 'next-intl';
import styles from './CookiePopup.module.scss';

type CookiePopupProps = {
    translations?: any;
};

export const CookiePopup: React.FC<CookiePopupProps> = ({}) => {
    const t = useTranslations('cookiePopup');
    const [isHydrated, setIsHydrated] = useState(false);
    const { cookiePopupShown, setCookiePopupShown, setCookieData } = useCookieStore();

    useEffect(() => {
        useCookieStore.persist.rehydrate();
        setIsHydrated(true);
    }, []);

    if (!isHydrated || cookiePopupShown) {
        return null;
    }

    return (
        <div>
            <div className={styles.root}>
                <Typography.P>{t('description')}</Typography.P>
                <div className={styles.wrapper}>
                    <div className={styles.buttons}>
                        <Button
                            variant="primary"
                            onClick={() => {
                                setCookieData({
                                    necessary: true,
                                    preferences: true,
                                    analytics: true,
                                    advertising: true,
                                });
                                setCookiePopupShown(true);
                            }}
                        >
                            <Typography.Button>{t('buttons.accept')}</Typography.Button>
                        </Button>

                        <Button
                            variant="secondary"
                            className={styles.rejectButton}
                            onClick={() => {
                                setCookieData({
                                    necessary: true,
                                    preferences: false,
                                    analytics: false,
                                    advertising: false,
                                });
                                setCookiePopupShown(true);
                            }}
                        >
                            <Typography.Button>{t('buttons.cancel')}</Typography.Button>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};
