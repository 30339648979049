import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

export type CookieData = {
    necessary: boolean;
    preferences: boolean;
    analytics: boolean;
    advertising: boolean;
};

type CookieState = {
    cookiePopupShown?: boolean;
    setCookiePopupShown: (value: boolean) => void;
    cookieData: CookieData;
    setCookieData: (value: CookieData) => void;
};

export const useCookieStore = create<CookieState>()(
    persist(
        set => ({
            cookiePopupShown: false,
            setCookiePopupShown: value => set({ cookiePopupShown: value }),
            cookieData: {
                necessary: true,
                preferences: false,
                analytics: false,
                advertising: false,
            },
            setCookieData: value => set({ cookieData: value }),
        }),
        {
            name: 'cookiePopup',
            storage: createJSONStorage(() => localStorage),
            skipHydration: true,
        },
    ),
);
